import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styles from "./Home-styles.css";
import HeaderGrey from "Components/Headers/HeaderGrey/HeaderGrey";
import ButtonLarge from "Components/Buttons/ButtonLarge/ButtonLarge";

const Home = () => {
  const location = useLocation();
  const inscriptionLink = `/inscription${location.search}`;

  return (
    <div className="accueil">
      {/* Open Graph Meta Tags */}
      <Helmet>
        <title>GeoGaming - Créez des aventures ludiques</title>
        <meta property="og:title" content="GeoGaming : l'éditeur d'aventures" />
        <meta property="og:description" content="Transformez le monde en votre terrain de jeu avec GeoGaming !" />
        <meta property="og:image" content="https://edit.geo-gaming.com/img-index/editeur.webp" />
        <meta property="og:url" content={`https://editeur.geo-gaming.com${location.pathname}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="GeoGaming - Créez des aventures ludiques" />
        <meta name="twitter:description" content="Transformez le monde en votre terrain de jeu avec GeoGaming !" />
        <meta name="twitter:image" content="https://edit.geo-gaming.com/img-index/editeur.webp" />
      </Helmet>


      <HeaderGrey showLoginButton={true} showSignupButton={false} />
      <div className="containerHome">
        <div className="titreHome">
          <b className="geogamingLditeur">GeoGaming : l'éditeur d'aventures</b>
        </div>
        <div className="paragrapheHome">
          <div className="homeContainer">
            <p className="punchlineHome">
              GeoGaming transforme le monde en votre terrain de jeu.
            </p>
            <p className="punchlineHome">
              Professionnels et amateurs, cet éditeur est fait pour vous !
            </p>
            <p className="punchlineHome">
              Ecrivez vos histoires à travers balades et jeux de piste.
            </p>
            <p className="punchlineHome">
              Générez des revenus en rendant payant vos parcours.
            </p>
            <p className="punchlineHome">Commencez dès maintenant !</p>
          </div>
        </div>

        <ButtonLarge
          text="Commencer"
          color="var(--blanc)"
          hideBorder="false"
          hideIcon="false"
          link={inscriptionLink}
          backgroundColor="var(--blue-geogaming)"
        />
      </div>

      <div className="containerVideo">
        <iframe
          className="videoHome"
          src="https://www.youtube.com/embed/4AlYd39rmoE"
          title="Créez des aventures ludiques hors du commun avec GeoGaming"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
